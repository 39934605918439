<template>
    <div class="part-detail-header-logo">
        <img
            v-if="imageExist"
            :src="brand_image_url"
            :alt="partDetail.brand_name"
            @error="fallbackBrandName"
        />
        <div v-else class="part-detail-header-logo_placeholder">
            <span>{{ partDetail.brand_name }}</span>
        </div>
    </div>
</template>

<script>
export default Vue.component("driv-part-detail-page-logo", {
    props: {
        partDetail: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            imageExist: true,
        }
    },
    computed: {
        brand_image_url() {
            const { wtb_brand_name } = this.partDetail;
            const { brand_name } = this.partDetail;
            const damPath = '/content/dam/marketing/North-America/fmmp/brand-logos/';
            var brandName = wtb_brand_name
                .toLowerCase()
                .replace('-', '')
                .replace('/','')
                .replace(/\s+/g, '');
            brandName =   brand_name == 'Wagner Sensors' ? 'wagnersensors' : brandName;

            return `${damPath}logo-${brandName}.png`;
        }
    },
    methods: {
        fallbackBrandName() {
            this.imageExist = false;
        }
    }
});
</script>
